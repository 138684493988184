import api from '@/services/api';
import store from '@/store';
import {useStore} from "vuex";


export default {

    async get(idTechnicage){
        const store = useStore();
        try {
            const response = await api.get('/technicages/' + idTechnicage) ;
            return response.data;
        } catch (error){
            console.error('Error fetching technicage:', error);
            throw error;
        }
    },

    async fetch(idUser, status){
        const store = useStore();
        try {
            const response = await api.get('/technicages/get/by/user/' + idUser + '?status=' + status) ;
            return response.data;
        } catch (error){
            console.error('Error fetching technicage:', error);
            throw error;
        }
    },

    async fetch_tests(idTechnicage, status, isBiologist){
        const store = useStore();
        try {
            let url = '/tests_technicages/by/technicage?idTechnicage=' + idTechnicage;
            if (status !== 'undefined') url +  '&status=' + status;
            if (isBiologist !== 'undefined') url +  '&isBiologist=' + isBiologist;
            const response = await api.get(url);
            return response.data;
        } catch (error){
            console.error('Error fetching technicage:', error);
            throw error;
        }
    },

    async validation(idTechnicage, data){
        try {
            const response = await api.post('/technicages/' + idTechnicage + '/update/status', data);
            return response.data;
        } catch (error){
            console.error('Error fetching technicage:', error);
            throw error;
        }
    },

    async update_state(idTechnicage, data){
        try {
            const response = await api.post('/technicages/' + idTechnicage + '/update/state', data);
            return response.data;
        } catch (error){
            console.error('Error fetching technicage:', error);
            throw error;
        }
    },

    async update_commentaire(idTechnicage, data){
        try {
            const response = await api.post('/technicages/' + idTechnicage + '/update/commentaire', data);
            return response.data;
        } catch (error){
            console.error('Error fetching technicage:', error);
            throw error;
        }
    },

    async update(idTechnicage, data, config){
        try {
            const response = await api.patch('/technicages/' + idTechnicage + '/update', data, config);
            return response.data;
        } catch (error){
            console.error('Error fetching technicage:', error);
            throw error;
        }
    }
}
