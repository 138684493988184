<template>
  <div v-if="!showSpinner">
    <div class="row" v-if="goodForValidation">
      <div class="col-md-12 text-center">
        <p>Vous allez valider le technicage et envoyer les résultats.</p>
        <p>Etes-vous sûr ?</p>
        <button class="btn btn-success" @click="validationTechnicage">Confirmer</button>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-md-12 text-center">
        <p>Tous les tests ne sont pas validés ou en anomalies.</p>
        <p>Veuillez traiter tous les tests.</p>
      </div>
    </div>
  </div>
  <spinner v-else></spinner>

</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import TechnicageService from "@/services/technicage/technicage";
import Spinner from "@/components/Spinner.vue";

export default {
  name: "Confirmation",
  components: {Spinner},
  data() {
    return {
      showSpinner: false
    }
  },
  methods: {
    ...mapMutations('technicage', ['setEditTechnicage', 'setTest', 'setTestsTechnicage']),
    async validationTechnicage(){
      this.showSpinner = true;
      if (this.goodForValidation()){
        let idTechnicage = this.edit_technicage.id;
        let status = 'VALBYTECH';
        let state = 'EDITBIO';
        if (this.isBiologist) status = 'VALBYBIOLOGIST';
        let formData = new FormData();
        formData.append('status', status);
        formData.append('state', state);
        formData.append('commentaireTechnician', this.edit_technicage.commentaireTechnician);
        formData.append('commentaireMedecin',  this.edit_technicage.commentaireMedecin);
        await TechnicageService.validation(idTechnicage, formData).then(() => {
          this.showSpinner = false;
          console.log('Technicage validé et envoyé chez le biologiste')
          this.goOut(false);
        })
      }
    },

    goOut(updateState){
      if (updateState){
        let stateCode = 'EDITTECH';
        if (this.isBiologist) stateCode = 'EDITBIO';
        TechnicageService.update_state(this.edit_technicage.id,{state: stateCode, isBiologist: this.isBiologist}).then(() => {
          this.setEditTechnicage(null);
          this.setTestsTechnicage(null);
          console.log('Etat du technicage changé');
        })
      }
      else{
        this.setEditTechnicage(null);
        this.setTestsTechnicage(null);
      }

    },

    goodForValidation(){
      let goodForValidation = true;
      if (this.tests_technicage != null){
        let i = 0;
        this.tests_technicage.find(item => {
          if (item != null){
            if (this.isBiologist){
              if (item.status != null && item.status.code === 'WIP') {
                goodForValidation = false;
              }
            }
            else if (item.statusTechnician != null && item.statusTechnician.code === 'WIP') {
              console.log(item)
              i++;
              goodForValidation = false;
            }
          }
        })
      }
      return goodForValidation;
    }
  },

  computed:{
    ...mapGetters('technicage', ['edit_technicage', "tests_technicage"]),
    ...mapGetters('auth', ['isBiologist']),

  }

}
</script>
<style scoped>

</style>
