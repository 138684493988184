<template>

  <div class="row mb-5" v-if="isValidated">
    <div class="col-md-12">
      <span class="text-success me-2"><b> Votre préparation de paillasse est validée</b></span>
      <button class="btn btn-success me-2" @click="$emit('next-step')">Etape suivante</button>
      <button class="btn btn-secondary me-2" @click="edit = true">Modifier</button>
    </div>
  </div>
  <div class="row">
    <div v-for="(workbenches, workbenchType) in datas" :key="equipmentType" class="col-md-6">
      <h4 class="mb-4">{{workbenchType}}</h4>
      <table class="table mb-5" style="width:100%">
        <tbody class="table-border-bottom-0" >
          <tr v-for="(item, index) in workbenches" :key="index">
            <td ><input type="checkbox" v-model="edit_technicage.workbenchTechnicages[index].done" class="form-check-input border-1 border-black" :disabled="isValidated && edit === false"></td>
            <td class="w-100" :class="styleItemDone(index)">{{item.label}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row" v-if="isValidated">
    <div class="col-md-12">
      <span class="text-success me-2"><b> Votre préparation de paillasse est validée</b></span>
      <button class="btn btn-success me-2" @click="$emit('next-step')">Etape suivante</button>
      <button class="btn btn-secondary me-2" @click="edit = true">Modifier</button>
    </div>
  </div>

</template>

<script>
import TableContainer from "@/components/TableContainer.vue";
import moment from "moment/moment";
import {mapGetters} from "vuex";

export default {
  name: "Workbench",
  components: {TableContainer},
  data(){
    return {
      datas: null,
      edit: false,
    }
  },

  methods:{

    getDatas(){
      let arrayDatas = {};
      this.edit_technicage.workbenchTechnicages.find(element => {
        console.log(element);
        if (!arrayDatas.hasOwnProperty(element.workbenchType)) arrayDatas[element.workbenchType] = {};
        let index = this.edit_technicage.workbenchTechnicages.findIndex(position => position.id === element.id);
        arrayDatas[element.workbenchType][index] = element;
      })
      this.datas = arrayDatas;
    },
    styleItemDone(index){
      if (this.edit_technicage.workbenchTechnicages[index].done !== true) return 'text-danger';
    }
  },

  computed: {
    ...mapGetters('technicage', ['edit_technicage']),
    isValidated(){
      for(const element of (this.edit_technicage.workbenchTechnicages)) {
        if (element.done === false || element.done == null) {
          return false;
        }
      }
      //Reinit edit
      this.edit = false;
      return true;
    }
  },

  mounted(){
    this.getDatas();
  }
}
</script>

<style scoped>

</style>
