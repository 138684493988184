<template>
  <div class="img-centered">
    <div class=" image-container text-center"
         @wheel="handleScroll"
         @mousedown="startDrag"
         @mousemove="onDrag"
         @mouseup="endDrag"
         @mouseleave="endDrag">
      <img :src="getGraphByPatient(codePatient, idPlatePlan)"
           alt="graph_result"
           :style="imageStyle"
           class="zoomable-image"
      >
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Graph",
  props: ['codePatient', 'idPlatePlan'],
  data() {
    return {
      zoomLevel: 1, // Niveau initial de zoom
      maxZoom: 4, // Zoom maximal
      minZoom: 1, // Zoom minimal
      isDragging: false, // Statut pour le drag
      startX: 0, // Position initiale X pour le drag
      startY: 0, // Position initiale Y pour le drag
      translateX: 0, // Translation sur l'axe X
      translateY: 0, // Translation sur l'axe Y,
      showSpinner: false
    }
  },
  computed:{
    ...mapGetters('technicage', ['graphs', 'getGraphByPatient']),
    imageStyle() {
      return {
        transform: `scale(${this.zoomLevel}) translate(${this.translateX}px, ${this.translateY}px)`,
        transition: this.isDragging ? 'none' : 'transform 0.3s ease',
      };
    },
  },

  methods:{
    handleScroll(event) {
      // Zoom avec la molette de la souris
      event.preventDefault();
      const zoomFactor = 0.1;
      if (event.deltaY > 0) {
        // Si on fait défiler vers le bas, on dézoome
        this.zoomLevel = Math.max(this.minZoom, this.zoomLevel - zoomFactor);
      } else {
        // Si on fait défiler vers le haut, on zoome
        this.zoomLevel = Math.min(this.maxZoom, this.zoomLevel + zoomFactor);
      }
    },
    startDrag(event) {
      // Activer le drag
      this.isDragging = true;
      this.startX = event.clientX - this.translateX;
      this.startY = event.clientY - this.translateY;
    },
    onDrag(event) {
      // Déplacer l'image si le drag est actif
      if (this.isDragging) {
        this.translateX = event.clientX - this.startX;
        this.translateY = event.clientY - this.startY;
      }
    },
    endDrag() {
      // Désactiver le drag
      this.isDragging = false;
    },
  },
  created() {

  }
}
</script>

<style scoped>
.image-container {
  width: 600px;
  overflow: hidden;
  cursor: grab;
}

.image-container:active {
  cursor: grabbing;
}

.zoomable-image {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Garde les proportions de l'image */
  transform-origin: center center; /* Centre du zoom */
}

.img-centered {
  display: flex;
  justify-content: center; /* Centrage horizontal */
  align-items: start; /* Centrage vertical */
}
</style>
