<template>
  <div>
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header">
            <b>Déconnexion imminente</b>
          </div>

          <div class="modal-body">
            <p class="text-red">Attention, vous allez être déconnecté dans <b>{{ timeToLogout }} secondes </b> et perdre votre travail en cours</p>
          </div>

          <div class="modal-footer">

            <button class="btn btn-secondary m-2" @click="this.resetTimer">
              Reste connecté
            </button>
            <button class="btn btn-success " @click="send_logout">
              Me déconnecter
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters, mapActions, mapMutations} from 'vuex';
import TechnicageService from "@/services/technicage/technicage"
export default {
  name: "ModalTimeLogout",

  methods: {
    ...mapMutations('auth', ['logout']),
    ...mapMutations('technicage', ['setEditTechnicage', 'setTest', 'setTestsTechnicage']),
    ...mapActions('timer', ['resetTimer']),
    send_logout(){
      let stateCode = 'EDITTECH';
      if (this.isBiologist) stateCode = 'EDITBIO'
      TechnicageService.update_state(this.edit_technicage.id, {state: stateCode, isBiologist: this.isBiologist}).then(() =>{
        this.$router.push('/');
        this.setEditTechnicage(null);
        this.setTestsTechnicage(null);
        this.logout();

      })
    }
  },
  computed : {
    ...mapGetters('timer', ['time']),
    ...mapGetters('technicage', ['edit_technicage']),
    ...mapGetters('auth', ['isBiologist']),

    timeToLogout(){
      let time = process.env.VUE_APP_TIME_EDITION_TECHNICAGE - this.time
      if(time == 0) {
        this.logout();
        this.$router.push('/');
      }
      else return process.env.VUE_APP_TIME_EDITION_TECHNICAGE - this.time;
    },

  },

}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 75%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
