<template>
  <div class="spinner-container">
    <div class="spinner">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
    <p v-if="percentage != null || message != null" class="percentage">
      <span  v-if="percentage != null">{{ percentage }}%<br></span>
      <span v-if="message != null">{{message}}</span>
    </p>
  </div>
</template>
<script>
export default {
  name: "Spinner",
  props: {
    percentage: {
      type: Number,
      default: null
    },
    size: {
      type: String,
      default: '40px' // Vous pouvez modifier la taille par défaut ici
    },
    message: {
      type: String,
      default: null
    }
  }
}
</script>
<style scoped>
.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centre le spinner et le texte */
  margin: 50px;
}

.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 0 auto; /* Centre le spinner */
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  animation-delay: -1.0s;
}

@keyframes bounce {
  0%, 100% {
    transform: scale(0.0);
  }
  50% {
    transform: scale(1.0);
  }
}

.percentage {
  margin-top: 10px; /* Espace entre le spinner et le texte */
  font-size: 16px; /* Taille de police pour le pourcentage */
  color: #333;
  vertical-align: center;/* Couleur du texte */
}
</style>
