import axios from 'axios';
import router from "@/router";
import store from "@/store";
import technicage from "@/store/modules/technicage";

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_KEY_RESULT_IDIR, // Replace with your API base URL
    headers: {

        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
    }
});

apiClient.interceptors.request.use(config => {

    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// Response interceptor to handle 401 errors
apiClient.interceptors.response.use((response) => {
    return response;
},  async error => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
            const refreshToken = localStorage.getItem('refresh_token');
            const response = await axios.post(process.env.VUE_APP_API_KEY_RESULT_IDIR + 'token/refresh', {
                refresh_token: refreshToken,
            });
            const { token: s } = response.data.token;
            localStorage.setItem('token', newAccessToken);
            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
            return apiClient(originalRequest); // Réessaye la requête initiale avec le nouveau token
        } catch (refreshError) {
            // Si le refresh échoue, redirigez l'utilisateur vers la page de connexion
            console.error("Session expirée, reconnectez-vous.");
            localStorage.removeItem('token');
            localStorage.removeItem('refresh_token');
            //Redirect to login or error page
            router.push('/');
            return Promise.reject(refreshError);
        }

    }
    return Promise.reject(error);
});


export default {
    get(resource) {
        return apiClient.get(resource);
    },
    post(resource, data, config = {}) {
        return apiClient.post(resource, data, config);
    },

    patch(resource, data, config = {}) {
        apiClient.headers = { 'Content-Type': 'application/merge-patch+json' };
        return apiClient.patch(resource, data, config);
    },

};
