<template>
  <div class="row">
    <div class="col-md-12">
      <div class="tabs">
        <button v-for="tab in tabHeaders" :key="tab.index" @click="selectedTab = tab.index; selectedPlateNumber = tab.number" :class="{ active: selectedTab === tab.index }">
          {{ tab.ref }}
        </button>
      </div>
      <div class="tab-content">
        <spinner v-if="showSpinner"></spinner>
        <div v-else>
          <plate-plan :num-rows="8" :num-cols="12" :num-plate="selectedPlateNumber" :num-plate-index="selectedTab"></plate-plan>
          <protocol-follow :num-plate-index="selectedTab" @back-result="$emit('back-result')"></protocol-follow>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TableTechnicage from '@/views/technicage/TableTechnicage.vue';
  import PlatePlan from "@/views/technicage/PlatePlan.vue";
  import ProtocolFollow from "@/views/technicage/ProtocolFollow.vue";
  import Spinner from "@/components/Spinner.vue";

  export default {
    name: 'TabsProtocol',
    components: {
      Spinner,
      ProtocolFollow,
      PlatePlan,
      TableTechnicage
    },
    data() {
      return {
        showSpinner: true,
        selectedTab: '',
        selectedPlateNumber: '',
      };
    },
    props: {
      tabHeaders: {
        type: Array,
        required: true
      },
    },

    created(){
      this.tabHeaders.find(tab => {
        if (tab.selected == true) {
          this.selectedTab = tab.index;
          this.selectedPlateNumber = tab.number;
        }
      })
      this.showSpinner = false;
    }

  };
</script>

<style scoped>
  .tabs {
    display: flex;
    border-bottom: 1px solid #ccc;
  }
  .tabs button {
    padding: 10px;
    cursor: pointer;
    border: none;
    background: none;
    outline: none;
  }
  .tabs button.active {
    border-bottom: 2px solid #000;
  }
  .tab-content {
    padding: 20px;
  }
</style>
