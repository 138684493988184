<template>
  <div class="progress-container">
    <div class="progress-bar bg-success" :style="{ width: progress + '%' }"></div>
    <div class="progress-info" v-if="progress != null || message != null">
      <span v-if="message != null">{{ message }}&nbsp;</span>
      <span v-if="progress != null">{{ progress }}%</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    progress: {
      type: Number,
      required: false,
      default: null,
      validator: value => value >= 0 && value <= 100 // Doit être entre 0 et 100
    },
    message:{
      type: String,
      required: false,
      default: null
    }
  }
}
</script>

<style scoped>
.progress-container {
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  margin: 20px 0;
}

.progress-bar {
  height: 30px;
  transition: width 0.3s ease;
}

.progress-info {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  font-weight: bold;
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}
</style>
