<template>
  <div class="row">
    <div class="col-md-12">
      <div class="tabs">
        <button v-for="tab in tabHeaders" :key="tab.index" @click="selectedTab = tab.index; selectedPlateNumber = tab.number; selectedIdPlatePlan = tab.id" :class="{ active: selectedTab === tab.index }">
          {{ tab.ref }}
        </button>
      </div>
      <div class="tab-content">
        <spinner v-if="showSpinner"></spinner>
        <div v-else>
          <table-test-technicage @disable-validation="accessDeniedValidation" @enable-validation="accessAllowValidation" @show-protocol="showProtocol" :id-plate-plan="selectedIdPlatePlan" ></table-test-technicage>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner.vue";
import PlatePlan from "@/views/technicage/PlatePlan.vue";
import ProtocolFollow from "@/views/technicage/ProtocolFollow.vue";
import TableTestTechnicage from "@/views/technicage/Tests/TableTestTechnicage.vue";

export default {
  name: "TabsPlatePlan" ,
  components: {TableTestTechnicage, ProtocolFollow, PlatePlan, Spinner},
  data() {
    return {
      showSpinner: true,
      selectedTab: '',
      selectedPlateNumber: '',
      selectedIdPlatePlan: 0
    };
  },
  props: {
    tabHeaders: {
      type: Array,
      required: true
    },
  },
  methods:{
    accessDeniedValidation(){
      this.$emit('disable-validation');
    },

    accessAllowValidation(){
      this.$emit('enable-validation');
    },

    showProtocol(){
      this.$emit('show-protocol');
    }
  },
  created(){
    this.showSpinner = false;
    this.tabHeaders.find(tab => {
      if (tab.selected == true) {
        this.selectedTab = tab.index;
        this.selectedPlateNumber = tab.number;
        this.selectedIdPlatePlan = tab.id;
      }
    })
    this.showSpinner = false;
  }
}
</script>



<style scoped>
.tabs {
  display: flex;
  border-bottom: 1px solid #ccc;
}
.tabs button {
  padding: 10px;
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
}
.tabs button.active {
  border-bottom: 2px solid #000;
}
.tab-content {
  padding: 20px;
}
</style>
