<template>

    <div class="row" v-for="(equipments, equipmentType) in datas" :key="equipmentType">
      <h4 class="mb-4">{{equipmentType}}</h4>
      <div v-for="(items, subEquipment) in equipments" :key="subEquipment">
        <h6>{{subEquipment}}</h6>
        <table class="table mb-5" style="width:100%">
          <thead>
          <tr>
            <th></th>
            <th></th>
            <th>Matériel prêt</th>
            <th>Date de modification</th>
            <th>Besoin de commande</th>
            <th>Date de commande</th>
            <th>Date de réception</th>
            <th>Commentaire</th>
          </tr>
          </thead>
          <tbody class="table-border-bottom-0" >
          <tr v-for="(item, index) in items" :key="index">
            <td >{{item.qty}}</td>
            <td class="w-50">{{item.label}}</td>
            <td><input type="checkbox" class="form-check-input border-1 border-black" v-model="edit_technicage.equipmentTechnicages[index].ready" @change="getDateReady(index)"></td>
            <td>{{ getDateFr(edit_technicage.equipmentTechnicages[index].updatedAt) }} </td>
            <td><input type="checkbox" class="form-check-input border-1 border-black" v-model="edit_technicage.equipmentTechnicages[index].orderRequest" ></td>
            <td>
              <div v-if="editOrderDate[index]">
                <input type="date" class="form-control" id="orderDate" v-model="edit_technicage.equipmentTechnicages[index].orderDate">
                <i class="bx bx-check" :style="getStyleIcon" @click="changeOrderDate(index, false)"></i>
              </div>
              <div class="text-center" v-else>
                {{getDateFr(edit_technicage.equipmentTechnicages[index].orderDate)}}
                <i class="bx bx-edit" :style="getStyleIcon" @click="changeOrderDate(index, true)"></i>
              </div>
            </td>
            <td>
              <div v-if="editReceiptDate[index]">
                <input type="date" class="form-control" id="orderDate" v-model="edit_technicage.equipmentTechnicages[index].orderDateReceipt">
                <i class="bx bx-check" :style="getStyleIcon" @click="changeReceiptDate(index, false)"></i>
              </div>
              <div class="text-center" v-else>
                {{getDateFr(edit_technicage.equipmentTechnicages[index].orderDateReceipt)}}
                <i class="bx bx-edit" :style="getStyleIcon" @click="changeReceiptDate(index, true)"></i>
              </div>
            </td>
            <td class="w-50"><textarea class="form-control" v-model="edit_technicage.equipmentTechnicages[index].comment" ></textarea></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
</template>

<script>

import {mapGetters} from "vuex";
import TableContainer from "@/components/TableContainer.vue";
import moment from "moment";


export default {
  name: "Equipment",
  components: {TableContainer},
  data(){
    return {
      datas: null,
      editOrderDate: [],
      editReceiptDate: [],
      fontSizeIcon: 20,
    }
  },

  methods:{

    moment(){
      return moment;
    },

    changeOrderDate(index, edit){
      this.editOrderDate[index] = edit;
    },

    changeReceiptDate(index, edit){
      this.editReceiptDate[index] = edit;
    },

    getTodayDate(){
      moment.locale('fr');
      // Get today's date in the format YYYY-MM-DD
      const today = moment(this.date).format('YYYY-MM-DD');
      return today;
    },

    getDatas(){
      let arrayDatas = {};
      this.edit_technicage.equipmentTechnicages.find(element => {
        if (!arrayDatas.hasOwnProperty(element.equipmentType)) arrayDatas[element.equipmentType] = {};
        if (!arrayDatas[element.equipmentType].hasOwnProperty(element.subEquipment)) arrayDatas[element.equipmentType][element.subEquipment] = {};
        let index = this.edit_technicage.equipmentTechnicages.findIndex(position => position.id === element.id);
        arrayDatas[element.equipmentType][element.subEquipment][index] = element;
      })
      this.datas = arrayDatas;
    },

    getDateReady(index){
      this.edit_technicage.equipmentTechnicages[index].updatedAt = this.getTodayDate();
    },

    getDateFr(dateStr){
      if (dateStr !== undefined){
        if (dateStr !== null) return moment(dateStr, "YYYY-MM-DD").format("DD/MM/YYYY");
      }
    }
  },

  computed: {
    ...mapGetters('technicage', ['edit_technicage']),
    getStyleIcon(){
      return 'font-size: ' + this.fontSizeIcon + 'px;'
    }
  },

  mounted(){
    this.getDatas();
  }
}


</script>


<style scoped>
.icon {
  font-size: 24px;
  color: #42b983;
}
</style>
