<template>
  <div>
    <div>
      <h4 class="fw-bold py-3 mb-4 mt-4">
        <span class="text-muted "><u>Etape 2: Suivi de protocole</u> <span style="font-size: 12px">(ref:{{refPlatePlan}})</span></span>
      </h4>
      <div class="row mb-4">
        <button class="btn p-5 w-25 " :disabled="disableReady" :class="styleBtnReady" @click="startProtocol">PRÊT À DÉMARRER</button>
        <p v-if="workbenchNotCompleted || platePlanNotValidated" class="text-danger w-50">
          Les étapes suivantes ne sont pas totalement complétées:<br>
          <span v-if="workbenchNotCompleted"><b>- Préparation de la paillasse non complète</b><br></span>
          <span v-if="platePlanNotValidated"><b>- Plan de plaque non validé</b><br></span>
        </p>
        <p class="w-50" v-if="dateStartProtocol != null">
          Début du protocole: {{getDateAndHourFr(dateStartProtocol)}}
        </p>
      </div>

      <div class=" justify-content-between align-items-center" v-for="(protocols, stepLabel) in datas" :key="stepLabel"  v-if="workbenchNotCompleted === false && platePlanNotValidated === false ">
        <div class="row">
          <h4 class="mb-4">{{stepLabel}}</h4>
          <div v-for="(items, subStepLabel) in protocols" :key="subStepLabel">
            <h6 v-if="subStepLabel != 0">{{subStepLabel}}</h6>

            <table class="table mb-5" >
              <thead>
              <tr>
                <th></th>
                <th>Date</th>
                <th>Heure</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in items" :key="index" style="width: 100%">
                <td style="width: 10%">
                  <input type="checkbox"
                         v-model="edit_technicage.platePlans[this.numPlateIndex].protocolFollows[index].done"
                         class="form-check-input border-1 border-black"
                         @change="getDateAndHourDone(index, $event)"
                         :disabled="dateStartProtocol == null || (item.description == 'UPLOAD' && (disableSendResult || item.done))" :style="styleCheckboxUpload(item.description)">
                </td>
                <td style="width: 10%">{{ getDateFr(edit_technicage.platePlans[this.numPlateIndex].protocolFollows[index].dateDone) }}</td>
                <td style="width: 10%">{{getHour(edit_technicage.platePlans[this.numPlateIndex].protocolFollows[index].hourDone)}}</td>
                <td style="width: 70%">
                  {{item.label}}<br/>
                  <i style="font-size: 11px" v-if="item.description !== '' && item.description !== 'UPLOAD'" >{{item.description}}</i>
                  <div class="row p-1" v-if="item.description == 'UPLOAD'">
                    <input type="file" @change="handleFileChange" class="form-control" id="file"  v-if="!showSpinner" :disabled="disableSendResult || item.done">
                    <button class="btn btn-success w-25 text-center mt-2" @click="sendResult(index)"  v-if="!showSpinner" :disabled="disableSendResult || item.done || this.file == ''">Envoyer résultat</button>
                    <progress-bar :progress="percentageSendResult" :message="messageSendResult" v-else></progress-bar>
                    <p v-if="message !== '' " :class="styleMsgUploadFile" ><b>{{message}}</b></p>
                    <p v-if="!isOnline" class="text-danger" ><b>Vous devez avoir une connexion internet pour insérer les résultats</b></p>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import moment from "moment/moment";
import {description} from "vue3-qrcode-reader/docs/.vuepress/config";
import api from "@/services/api";
import Spinner from "@/components/Spinner.vue";
import TechnicageService from "@/services/technicage/technicage";
import ProgressBar from "@/components/ProgressBar.vue";

export default {
  name: "ProtocolFollow",
  components: {ProgressBar, Spinner},
  data() {
    return {
      datas: null,
      workbenchNotCompleted: false,
      platePlanNotValidated: false,
      file: '',
      showSpinner: false,
      message: '',
      uploadFileOk: false,
      percentageSendResult: null,
      messageSendResult: null,
      dateStartProtocol: null,
      totalProtocolFollow: 0,
      totalCheckProtocolFollow: 0
    };
  },
  props: ['numPlateIndex'],
  computed: {
    ...mapGetters('technicage', ['edit_technicage']),
    ...mapGetters('platePlan', ['platePlans']),
    ...mapGetters('auth', ['isBiologist']),
    ...mapState('connection', ['isOnline']), // Accès à l'état du module connection
    refPlatePlan(){
      return this.edit_technicage.platePlans[this.numPlateIndex].ref
    },
    disableReady(){
      var disabled = false

      //Look for worbench item is not done
      for(const element of (this.edit_technicage.workbenchTechnicages)) {
        if (element.done === false || element.done == null) {
          this.workbenchNotCompleted = true;
          break;
        }
      }

      //if plate plan not validated
      if (this.edit_technicage.platePlans[this.numPlateIndex].validate === undefined ||
          this.edit_technicage.platePlans[this.numPlateIndex].validate === null ||
          this.edit_technicage.platePlans[this.numPlateIndex].validate === false){
        this.platePlanNotValidated = true;
      }
      else{
        this.platePlanNotValidated = false;
      }

      //if plate plan or workbench not validated = not ready to start protocol follow
      if (this.workbenchNotCompleted || this.platePlanNotValidated || this.dateStartProtocol != null) disabled = true;
      return disabled;
    },

    styleBtnReady(){

      //Bouton danger(red) is not ready to start
      if (this.workbenchNotCompleted || this.platePlanNotValidated){
        return 'btn-danger';
      }

      //Bouton success (green) ready to start
      else return 'btn-success';
    },

    styleMsgUploadFile(){
      if (this.uploadFileOk) return 'text-success';
      else return 'text-danger';
    },
    disableSendResult(){
      //if all case are check
      if (this.totalProtocolFollow === this.totalCheckProtocolFollow && this.isOnline){
        return false;
      }
      else return true;
    },

  },
  methods: {
    ...mapActions('connection', ['checkConnection']), // Lancer l'action du module connection
    ...mapActions('technicage', ['fetch_tests']),
    description() {
      return description;
    },
    moment(){
      return moment;
    },

    getDatas(){
      let arrayDatas = {};
      this.edit_technicage.platePlans[this.numPlateIndex].protocolFollows.find(element => {

        //si il n'y a pas de dateStartProtocol et que un element a déjà un startProtocol => cela signifie que le protocol est déjà start
        if(this.dateStartProtocol == null && element.startProtocol !== 'undefined' && element.startProtocol != null) this.dateStartProtocol = element.startProtocol;
        if (!arrayDatas.hasOwnProperty(element.stepLabel)) arrayDatas[element.stepLabel] = {};
        var subStepLabel = element.subStepLabel;
        if (subStepLabel == null){
          subStepLabel = 0
        }
        if (!arrayDatas[element.stepLabel].hasOwnProperty(subStepLabel)) {
          arrayDatas[element.stepLabel][subStepLabel] = {};
        }
        let index = this.edit_technicage.platePlans[this.numPlateIndex].protocolFollows.findIndex(position => position.id === element.id);
        arrayDatas[element.stepLabel][subStepLabel][index] = element;

        //Count total protocol follow without upload
        if (element.description !== 'UPLOAD') this.totalProtocolFollow++;
        //Count total check
        if (element.done) this.totalCheckProtocolFollow++;
      })
      this.datas = arrayDatas;
    },
    getDateAndHourDone(index, event){

      if (this.edit_technicage.platePlans[this.numPlateIndex].protocolFollows[index].done){
        this.totalCheckProtocolFollow++;
        this.edit_technicage.platePlans[this.numPlateIndex].protocolFollows[index].dateDone = this.getTodayDate();
        this.edit_technicage.platePlans[this.numPlateIndex].protocolFollows[index].hourDone = this.getHourNow();
      }
      else{
        this.totalCheckProtocolFollow--;
        this.edit_technicage.platePlans[this.numPlateIndex].protocolFollows[index].dateDone = null;
        this.edit_technicage.platePlans[this.numPlateIndex].protocolFollows[index].hourDone = null;
      }

    },

    getTodayDate(){
      moment.locale('fr');
      // Get today's date in the format YYYY-MM-DD
      const today = moment(this.date).format('YYYY-MM-DD');
      return today;
    },

    getHourNow(){
      moment.locale('fr');
      // Get today's date in the format YYYY-MM-DD
      const now = moment(this.date).format('HH:mm');
      return now;
    },

    getDateFr(dateStr){
      if (dateStr !== undefined ){
        if (dateStr !== null) return moment(dateStr, "YYYY-MM-DD").format("DD/MM/YYYY");
      }
    },

    getHour(hourStr){
      if (hourStr !== undefined){
        if (hourStr !== null ){
          //When hour come by click
          if (moment(hourStr, 'HH:mm', true).isValid()){
            return moment(hourStr, "HH:mm").format("HH:mm");
          }
          else{
            hourStr = moment(hourStr).utc().format('HH:mm');
            return moment(hourStr, "HH:mm").format("H:mm");
          }
        }
      }

    },

    startProtocol(){
      this.dateStartProtocol = moment().format('YYYY-MM-DD HH:mm');
      this.edit_technicage.platePlans[this.numPlateIndex].protocolFollows.forEach((data, index) => {
        this.edit_technicage.platePlans[this.numPlateIndex].protocolFollows[index].startProtocol = this.dateStartProtocol;
      })
    },

    getDateAndHourFr(date){
      return moment(date).format('DD/MM/YYYY HH:mm');
    },

    handleFileChange(event) {
      this.file = event.target.files[0];
    },

    async sendResult(index){

      this.showSpinner = true;

      let _self = this;
      let todayDate = _self.getTodayDate();
      let hourNow = _self.getHourNow();

      //Start send result to 0%
      this.percentageSendResult = 0;
      this.messageSendResult = 'Enregistrement du technicage';

      if (!this.file) {
        this.showSpinner = false;
        this.percentageSendResult = null;
        alert('ATTENTION: Le fichier est manquant');
        return;
      }

      //Save technicage before
      const data = JSON.parse(JSON.stringify(this.edit_technicage));
      data.platePlans.forEach((element, index) => {
        data.platePlans[index].platePlanData = this.platePlans[index];
      })
      data.platePlans[this.numPlateIndex].protocolFollows[index].done = true;
      data.platePlans[this.numPlateIndex].protocolFollows[index].dateDone = todayDate;
      data.platePlans[this.numPlateIndex].protocolFollows[index].hourDone = hourNow;
      let idTechnicage = this.edit_technicage.id;
      await TechnicageService.update(idTechnicage, data, {
        headers:{
          'Content-Type': 'application/merge-patch+json'
        },
        onUploadProgress: progressEvent => {
          if (progressEvent.total) {
            this.percentageSendResult = 25
          }
        }
      }).then(() => {
        this.messageSendResult = 'Traitement des résultats';
        const formData = new FormData();
        formData.append('id_plate_plan', this.edit_technicage.platePlans[this.numPlateIndex].id);
        formData.append('is_biologist', this.isBiologist)
        formData.append('file', this.file);

        const response = api.post('/upload/result/agilent', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: progressEvent => {
            this.percentageSendResult = 50;
            setTimeout(() => {
              this.percentageSendResult = 75;
            }, 5000);
          }
        }).catch(() => {
          _self.edit_technicage.platePlans[this.numPlateIndex].protocolFollows[index].done = false;
          _self.edit_technicage.platePlans[this.numPlateIndex].protocolFollows[index].dateDone = null;
          _self.edit_technicage.platePlans[this.numPlateIndex].protocolFollows[index].hourDone = null;
          _self.showSpinner = false;
          _self.uploadFileOk = false;
          _self.percentageSendResult = 0;
          _self.message = 'Le fichier n\'a pas pu être téléchargé';
        });

        response.then(() =>{
          this.percentageSendResult = 100;
          setTimeout(() => {
            _self.edit_technicage.platePlans[this.numPlateIndex].protocolFollows[index].done = true;
            _self.edit_technicage.platePlans[this.numPlateIndex].protocolFollows[index].dateDone = todayDate;
            _self.edit_technicage.platePlans[this.numPlateIndex].protocolFollows[index].hourDone = hourNow;
            this.$emit('back-result');
            _self.showSpinner = false;
            _self.uploadFileOk = true;
            _self.message = 'Le fichier agilent a bien été enregistré';

          }, 1000);
        })
        response.catch(() => {
          _self.edit_technicage.platePlans[this.numPlateIndex].protocolFollows[index].done = false;
          _self.edit_technicage.platePlans[this.numPlateIndex].protocolFollows[index].dateDone = null;
          _self.edit_technicage.platePlans[this.numPlateIndex].protocolFollows[index].hourDone = null;

          //if upload not ok, reupdate technicage with upload to false
          TechnicageService.update(idTechnicage, data, {headers:{'Content-Type': 'application/merge-patch+json'}});
          _self.showSpinner = false;
          _self.uploadFileOk = false;
          _self.percentageSendResult = 0;
          _self.message = 'Le fichier n\'a pas pu être téléchargé';
        })

      })
    },

    styleCheckboxUpload(desc){
      if (desc == 'UPLOAD') return 'display:none;'
    }
  },

  mounted() {
    this.checkConnection();
    this.getDatas();
  }

}
</script>
<style scoped>

</style>
