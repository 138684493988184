<template>
  <div>
    <qrcode-stream @detect="onDetect" @init="onInit" @error="onError" :paused="paused" style="display: none">
    </qrcode-stream>
    <p v-if="error">{{ error }}</p>
    <!-- Tableau généré dynamiquement -->
    <div class="row mt-4 mb-4">
      <div class="col-md-8">
        <h4 class="fw-bold">
          <span class="text-muted float-start"><u>Etape 1: Plan de plaque</u> <span style="font-size: 12px">(ref:{{title}})</span></span>
        </h4>
      </div>
      <div class="col-md-4">
          <span class="text-muted float-end">Total test à réaliser: {{edit_technicage.nbTestToRealized}}</span><br>
        <span class="text-muted float-end">Nombre de tests scannés: {{nbTestScanned}}/{{edit_technicage.nbTestToRealized}}</span>
      </div>
    </div>
    <p class="text-secondary">
      Scanner vos échantillons<br>
      En cas de saisie manuelle, veuillez <b>appuyer sur Entrer</b> pour passer au puit suivant.<br>
      <span v-if="clickTab" class="text-danger">La touche <b>Tabulation</b> n'est pas autorisée</span>
    </p>

    <div class="d-flex justify-content-between align-items-center w-100">
      <table class="table text-center" v-if="platePlans != null && platePlans.length > 0">
        <thead>
        <tr>
          <th></th>
          <th v-for="col in cols" :key="col">{{ col }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(row, rowIndex) in rows" :key="rowIndex">
          <td>{{ row }}</td>
          <td v-for="(col, colIndex) in cols" :key="colIndex">
            <!-- disable colIndex + 1 car les tableaux commence avec l'indice 0 -->
            <input class="form-control" v-model="platePlans[numPlateIndex][rowIndex][colIndex]"
                   :style="styleGamme(rowIndex, colIndex)"
                   @keydown.enter="focusNext(rowIndex, colIndex, $event)"
                   :disabled="disabledEvenColumn(rowIndex, colIndex)"
                   :ref="'input-' + rowIndex + '-' + colIndex"
                   :id="'input-' + rowIndex + '-' + colIndex"
                   @keydown.tab="disableTab"
                   @focusout="changeValue(rowIndex, colIndex, $event)"
            />
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="row mb-2" v-if="!edit_technicage.platePlans[this.numPlateIndex].validate && nbTestScanned === edit_technicage.nbTestToRealized">
      <div class="col-md-12 text-center">
        <p class="text-danger" style="font-size: 26px"><b>Vérifiez le plan de plaque puis validez</b></p>
        <button class="btn btn-success" @click="validatePlatePlan">Valider le plan de plaque</button>
      </div>
    </div>
    <div class="row mb-2" v-if="edit_technicage.platePlans[this.numPlateIndex].validate ">
      <div class="col-md-12 text-center">
        <p class="text-success" style="font-size: 26px"><b>Votre plan de plaque a été validé</b></p>
      </div>

    </div>
  </div>
</template>
<script>

import { QrcodeStream } from "vue-qrcode-reader";
import {mapGetters, mapMutations} from "vuex";
import moment from 'moment';
import 'moment/locale/fr';
export default {
  name: "PlatePlan",
  components: {
    QrcodeStream,
  },
  data() {
    return {
      gammeLine: 1,
      gammeColumn: [1, 2, 3, 4, 5, 6],
      gammeColor: '#ff8000',
      startEditLine: 2,
      startEditColumn: 1 ,
      error: '',
      paused: false,
      platePlan: null,
      tableInputEnable: [],
      nbTestScanned: 0,
      clickTab: false
    };
  },
  props: ['numRows', 'numCols', 'numPlate', 'numPlateIndex'],

  computed: {
    ...mapGetters('technicage', ['edit_technicage']),
    ...mapGetters('platePlan', ['platePlans']),
    rows() {
      // Génère un tableau de lettres (A, B, C, ...)
      return Array.from({ length: this.numRows }, (_, i) =>
          String.fromCharCode(65 + i));
    },
    cols() {
      // Génère un tableau de chiffres (1, 2, 3, ...)
      return Array.from({ length: this.numCols }, (_, i) => i + 1);
    },

    title(){
      return this.edit_technicage.platePlans[this.numPlateIndex].ref;
    },

  },

  methods: {
    ...mapMutations('platePlan', ['setListPlatePlans']),

    getTodayDate(){
      moment.locale('fr');
      // Get today's date in the format YYYY-MM-DD
      const today = moment(this.date).format('YYYY-MM-DD');
      return today;
    },

    validatePlatePlan(){
      this.edit_technicage.platePlans[this.numPlateIndex].validate = true;
      this.edit_technicage.platePlans[this.numPlateIndex].dateValidate = this.getTodayDate();
    },

    generateTable() {
      let platePlanNumber = [];
      // Initialise le tableau de données avec des tableaux vides
      let tableData =  Array.from({ length: this.numRows }, () =>
          Array.from({ length: this.numCols }, () => '')
      );

      platePlanNumber[this.numPlateIndex] = tableData;
      this.setListPlatePlans(platePlanNumber);
    },

    styleGamme(rowIndex, colIndex){
      if (rowIndex === (this.gammeLine - 1) && this.gammeColumn.find(element => element - 1 === colIndex)) return 'background-color: ' + this.gammeColor;
      return '';
    },

    disabledEvenColumn(rowIndex, colIndex){

      //if column Even (column test)
      if ((colIndex + 1) % 2 === 0 || (this.gammeLine - 1 == rowIndex && this.gammeColumn.find(element => element - 1 === colIndex))) return true;
      else if ((this.startEditLine <= (rowIndex + 1)) || (this.startEditColumn  <= (colIndex + 1))){
        if (!this.tableInputEnable.hasOwnProperty(rowIndex.toString() + '_' + colIndex.toString())) return true;
      }
      return false;
    },

    //Manage value on plate plan and the puit of test and next input
    focusNext(rowIndex, colIndex, event){

      //Si entree value par qrcode
      if(event != null) event.preventDefault(); // Empêche le comportement par défaut de la touche "Entrée"
      //Complete la colonne d'à côté avec la même valeur (une valeur normal + une valeur test)
      //Si il reste encore des cases disponible
      if (this.platePlans[this.numPlateIndex][rowIndex][colIndex] == null ||  this.platePlans[this.numPlateIndex][rowIndex][colIndex] == ''){
        this.edit_technicage.platePlans[this.numPlateIndex].validate = false;
        this.edit_technicage.platePlans[this.numPlateIndex].dateValidate = null;
        this.nbTestScanned--;
      }
      else{

        if(this.nbTestScanned < this.edit_technicage.nbTestToRealized){
          this.nbTestScanned++;
        }
      }
      if (colIndex + 2 <= this.numCols){

        this.platePlans[this.numPlateIndex][rowIndex][colIndex + 1] = this.platePlans[this.numPlateIndex][rowIndex][colIndex];

        // Calculer les indices du prochain champ de saisie. Le prochain champ de saisie se situe à la ligne suivante
        let nextColIndex = colIndex;
        let nextRowIndex = rowIndex + 1;

        // Si l'indice de ligne dépasse le nombre de lignes, retourner à la première ligne en augmentant de 1 la colonne
        if (nextRowIndex >= this.numRows) {
          //On recommence à la première ligne
          nextRowIndex = 0;
          nextColIndex = colIndex + 2;
          //Si c'est la ligne et la colonne de la gamme, on passe à la ligne suivante
          if (nextRowIndex == this.gammeLine - 1 && this.gammeColumn.find(element => element - 1 === nextColIndex )) nextRowIndex++;
        }

        //Si des colonnes sont encore disponible
        if (nextColIndex < this.numCols){
          // Définir le focus sur le prochain champ de saisie
          this.$nextTick(() => {
            const nextInput = this.$refs[`input-${nextRowIndex}-${nextColIndex}`][0];
            if (nextInput) {
              nextInput.focus();
            }
          });
        }

      }
    },
    async onDetect(decodedString) {
      const focusedElement = document.activeElement;
      let idInputFocus = focusedElement.id;
      let rowIndex = idInputFocus.split('-')[1];
      let colIndex = idInputFocus.split('-')[2];
      // Méthode appelée lors de la détection d'un code QR
      let value = JSON.stringify(decodedString.map((code) => code.rawValue));
      this.platePlans[this.numPlateIndex][rowIndex][colIndex] = value.slice(2).slice(0, -2);
      this.focusNext(parseInt(rowIndex,10), parseInt(colIndex,10));

      //Pause to scan no interrupted
      this.paused = true;
      await this.timeout(50);
      this.paused = false;

    },

    onInit(promise) {
      promise.catch(error => {
        if (error.name === 'AbortError') {
          this.error = "The QR code scanning operation was aborted.";
        } else {
          this.error = error.message;
        }
      });
    },

    //Error lecteur phone
    onError(err) {
      this.error = `[${err.name}]: `

      if (err.name === 'NotAllowedError') {
        this.error += 'you need to grant camera access permission'
      } else if (err.name === 'NotFoundError') {
        this.error += 'no camera on this device'
      } else if (err.name === 'NotSupportedError') {
        this.error += 'secure context required (HTTPS, localhost)'
      } else if (err.name === 'NotReadableError') {
        this.error += 'is the camera already in use?'
      } else if (err.name === 'OverconstrainedError') {
        this.error += 'installed cameras are not suitable'
      } else if (err.name === 'StreamApiNotSupportedError') {
        this.error += 'Stream API is not supported in this browser'
      } else if (err.name === 'InsecureContextError') {
        this.error +=
            'Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.'
      } else {
        this.error += err.message
      }
    },

    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms)
      })
    },

    //Désactivate tab for plateplan
    disableTab(event){
      if (event.key === "Tab") {
        this.clickTab = true;
        event.preventDefault();
      }
    },

    changeValue(rowIndex, colIndex, event){

    }
  },

  created() {

    let _self = this;
    if (_self.platePlans == null ||
        (!_self.platePlans.hasOwnProperty(_self.numPlateIndex)) ||
        (_self.platePlans.hasOwnProperty(_self.numPlateIndex) && _self.platePlans[_self.numPlateIndex].length === 0)){
      this.generateTable();
    }

    //If platePlan no have data => generate
    if (this.edit_technicage.platePlans[this.numPlateIndex].platePlanData.length > 0){
      this.edit_technicage.platePlans[this.numPlateIndex].platePlanData.forEach(function(value, index){
        _self.platePlans[_self.numPlateIndex][value.numberRow][value.numberCol] = value.data;
      });
    }

    let count = 0;
    let skippedCells = 6;  // Nombre de cellules à ignorer dans la première ligne

    // Parcourir colonne par colonne
    for (let j = 0; j < _self.platePlans[_self.numPlateIndex][0].length; j++) {

        for (let i = 0; i < _self.platePlans[_self.numPlateIndex].length; i++) { // Commencer à la deuxième ligne (i = 1)
          // Ignorer les 6 premières cellules de la première ligne
          if (i === 0 && skippedCells > 0) {
            skippedCells--;  // On saute ces cellules
            continue;        // On passe à la cellule suivante
          }
          if (j % 2 === 0){
            // Compter les cellules de la deuxième ligne jusqu'à la dernière ligne
            count++;

            //Si le nombre de test n'a pas dépassé le nombre de test a réalisé
            if (this.edit_technicage.nbTestToRealized >= count){
              this.tableInputEnable[i.toString() + '_' + j.toString()] = count;
            }
            if (this.platePlans[_self.numPlateIndex][i][j] !== null && this.platePlans[_self.numPlateIndex][i][j] !== '') this.nbTestScanned++;
          }

        }
    }

    this.$nextTick(() => {
      const nextInput = this.$refs[`input-${this.startEditLine - 1}-${this.startEditColumn - 1}`][0];
      if (nextInput) {
        nextInput.focus();
      }
    });
  }
}
</script>
<style scoped>

</style>
