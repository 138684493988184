<template>
  <div>
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header">
            <b>Contrôle pallier</b>
          </div>
          <div class="modal-body" >
            <table class="table text-center w-100" >
              <thead>
                <tr>
                  <th colspan="6">
                    Validation atteinte du palier : valeur d'absorbance à 10 min est égale à +/- 10% de la valeur d'absorbance en fin de manipulation
                  </th>
                </tr>
                <tr>
                  <th>200 µM</th>
                  <th>100 µM</th>
                  <th>50 µM</th>
                  <th>25 µM</th>
                  <th>12,5 µM</th>
                  <th>0 µM</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td :class="getStyleCell(getControlBearing.std1.toFixed(3))" class="text-white"><b>{{getControlBearing.std1.toFixed(3)}}%</b></td>
                  <td :class="getStyleCell(getControlBearing.std2.toFixed(3))" class="text-white"><b>{{getControlBearing.std2.toFixed(3)}}%</b></td>
                  <td :class="getStyleCell(getControlBearing.std3.toFixed(3))" class="text-white"><b>{{getControlBearing.std3.toFixed(3)}}%</b></td>
                  <td :class="getStyleCell(getControlBearing.std4.toFixed(3))" class="text-white"><b>{{getControlBearing.std4.toFixed(3)}}%</b></td>
                  <td :class="getStyleCell(getControlBearing.std5.toFixed(3))" class="text-white"><b>{{getControlBearing.std5.toFixed(3)}}%</b></td>
                  <td :class="getStyleCell(getControlBearing.blk.toFixed(3))" class="text-white"><b>{{getControlBearing.blk.toFixed(3)}}%</b></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer" >
            <button class="btn btn-secondary m-2" @click="closeModal()">
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import api from "@/services/api"
import Spinner from "@/components/Spinner.vue";
import Graph from "@/views/technicage/Tests/Graph.vue";

export default {
  name: "ModalControlBearing",
  components: {Graph, Spinner},
  props : ['idPlatePlan'],
  data() {
      return {
        showSpinner: false
      }
  },

  computed:{
    ...mapGetters('technicage', ['tests_technicage']),
    imageStyle() {
      return {
        transform: `scale(${this.zoomLevel}) translate(${this.translateX}px, ${this.translateY}px)`,
        transition: this.isDragging ? 'none' : 'transform 0.3s ease',
      };
    },

    getControlBearing(){
      //Just one controlBearing by plate plan
      return this.tests_technicage[0].resultCollection.platePlan.controlBearings[0];
    }
  },

  methods:{
    closeModal(){
      this.$emit('close');
    },
    getStyleCell(value){
      if (value < -10 || value > 10) return 'bg-danger';
      else return 'bg-success';
    }
  },

  mounted() {

  }

}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 75%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 0 auto;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.image-container {
  width: 600px; /* Taille de la zone visible */
  height: 800px;
  overflow: hidden;
  cursor: grab;
}

.image-container:active {
  cursor: grabbing;
}

.zoomable-image {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Garde les proportions de l'image */
  transform-origin: center center; /* Centre du zoom */
}

.img-centered {
  display: flex;
  justify-content: center; /* Centrage horizontal */
  align-items: center; /* Centrage vertical */
}
</style>
