<template>
  <div>
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header">
            <b>Résultats</b>
          </div>
          <div class="modal-body" >
              <div class="img-centered">
                <div class="mb-3 image-container text-center" v-if="!showSpinner"
                     @wheel="handleScroll"
                     @mousedown="startDrag"
                     @mousemove="onDrag"
                     @mouseup="endDrag"
                     @mouseleave="endDrag">
                  <img :src="getGraphByPatient(codePatient, idPlatePlan)"
                       alt="graph_result"
                       :style="imageStyle"
                       class="zoomable-image"
                  >
                </div>
                <spinner v-else></spinner>
              </div>
          </div>
          <div class="modal-footer" >
            <button class="btn btn-secondary m-2" @click="closeModal()">
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import api from "@/services/api"
import Spinner from "@/components/Spinner.vue";
import Graph from "@/views/technicage/Tests/Graph.vue";

export default {
  name: "ModalGraph",
  components: {Graph, Spinner},
  props : ['codePatient', 'idPlatePlan'],
  data() {
      return {
        zoomLevel: 1, // Niveau initial de zoom
        maxZoom: 4, // Zoom maximal
        minZoom: 1, // Zoom minimal
        isDragging: false, // Statut pour le drag
        startX: 0, // Position initiale X pour le drag
        startY: 0, // Position initiale Y pour le drag
        translateX: 0, // Translation sur l'axe X
        translateY: 0, // Translation sur l'axe Y,
        showSpinner: false
      }
  },

  computed:{
    ...mapGetters('technicage', ['graphs', 'getGraphByPatient']),
    imageStyle() {
      return {
        transform: `scale(${this.zoomLevel}) translate(${this.translateX}px, ${this.translateY}px)`,
        transition: this.isDragging ? 'none' : 'transform 0.3s ease',
      };
    },
  },

  methods:{

    handleScroll(event) {
      // Zoom avec la molette de la souris
      event.preventDefault();
      const zoomFactor = 0.1;
      if (event.deltaY > 0) {
        // Si on fait défiler vers le bas, on dézoome
        this.zoomLevel = Math.max(this.minZoom, this.zoomLevel - zoomFactor);
      } else {
        // Si on fait défiler vers le haut, on zoome
        this.zoomLevel = Math.min(this.maxZoom, this.zoomLevel + zoomFactor);
      }
    },
    startDrag(event) {
      // Activer le drag
      this.isDragging = true;
      this.startX = event.clientX - this.translateX;
      this.startY = event.clientY - this.translateY;
    },
    onDrag(event) {
      // Déplacer l'image si le drag est actif
      if (this.isDragging) {
        this.translateX = event.clientX - this.startX;
        this.translateY = event.clientY - this.startY;
      }
    },
    endDrag() {
      // Désactiver le drag
      this.isDragging = false;
    },

    closeModal(){
      this.$emit('close');
    },
    wait(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },

  async mounted() {
    this.showSpinner = true;
    await this.wait(100);
    this.showSpinner = false;
  }

}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 50%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 0 auto;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.image-container {
  width: 600px; /* Taille de la zone visible */
  height: 800px;
  overflow: hidden;
  cursor: grab;
}

.image-container:active {
  cursor: grabbing;
}

.zoomable-image {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Garde les proportions de l'image */
  transform-origin: center center; /* Centre du zoom */
}

.img-centered {
  display: flex;
  justify-content: center; /* Centrage horizontal */
  align-items: center; /* Centrage vertical */
}
</style>
