<template>
  <template v-for="(platePlan, index) in edit_technicage.platePlans" :key="platePlan.ref"  >
    <div class="platePlan mb-5" v-if="selectedPlatePlan === platePlan.ref">
      <h3>Nom de la plaque: {{platePlan.ref}}</h3>
      <protocol-follow :num-plate-index="index"></protocol-follow>
      <button @click="changePlate(index - 1)" class="btn btn-primary mt-2" style="margin-left: 5px" v-if="edit_technicage.platePlans.length > 1 && index !== 0">Protocole précédent</button>
      <button @click="changePlate(index + 1)" class="btn btn-primary mt-2" style="margin-left: 5px" v-if="edit_technicage.platePlans.length > 1 && (index) < edit_technicage.platePlans.length">Protocole précédent</button>
    </div>
  </template>
  <div>

    <!--<button class="btn btn-success float-end" style="margin-left: 2px" @click="goToResult()">Aller au résultat</button>-->
  </div>

</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import PlatePlan from "@/views/technicage/PlatePlan.vue";
import ProtocolFollow from "@/views/technicage/ProtocolFollow.vue";

export default {
  name: "ManyProtocolFollow",
  components: {ProtocolFollow, PlatePlan},
  data(){
    return {
      selectedPlatePlan: '',
    }
  },

  methods: {
    ...mapMutations('technicage', ['setShowTest']),
    changePlate(index){
      this.selectedPlatePlan = this.edit_technicage.platePlans[index].ref;
    },

    goToResult(){
      this.setShowTest(true);
    }
  },

  computed: {
    ...mapGetters('technicage', ['edit_technicage']),
  },

  mounted() {
    if (this.edit_technicage.platePlans.length > 0){
      this.selectedPlatePlan = this.edit_technicage.platePlans[0].ref;
    }
  }
}
</script>

<style scoped>

</style>
