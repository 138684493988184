<template>
  <div class="bs-stepper" id="stepper">
    <div class="bs-stepper-header" role="tablist">
      <!-- your steps here -->
      <template v-for="(step, index) in stepHeaders" :key="step.name"  >
        <div class="step" :class="{ active: selectedStep === step.name }">
          <button  class="step-trigger" style="cursor: auto" @click="goStep(index, step)">
            <span class="bs-stepper-circle">{{ index + 1 }}</span>
            <span class="bs-stepper-label">{{ step.label }}</span>
          </button>
        </div>

      </template>
    </div>
    <div class="bs-stepper-content">
      <div>
        <component :is="selectedComponent" :selectedStep="selectedStep" @next-step="nextStep()" @back-result="$emit('back-result')"></component>
      </div>
      <button @click="$emit('back-result')" class="btn btn-info float-end " style="margin-left: 5px" v-if="goResult">Aller au résultat</button>
      <button @click="nextStep()" class="btn btn-secondary float-end" style="margin-left: 5px" v-if="displayButtonNext">Suivant</button>
      <button @click="previousStep()" class="btn btn-secondary float-end" v-if="selectedIndex + 1 > 1">Précédent</button>
    </div>
  </div>
</template>

<script>

import TableTestTechnicage from '@/views/technicage/Tests/TableTestTechnicage.vue';
import Confirmation from '@/views/technicage/Confirmation.vue';
import Equipment from "@/views/technicage/Equipment.vue";
import Workbench from "@/views/technicage/Workbench.vue";
import ManyPlatePlan from "@/views/technicage/ManyPlatePlan.vue";
import ManyProtocolFollow from "@/views/technicage/ManyProtocolFollow.vue";
import ProtocolTechnicage from "@/views/technicage/ProtocolTechnicage.vue";

export default {
  name: "Stepper",
  components: {
    TableTestTechnicage,
    Confirmation,
    Equipment,
    Workbench,
    ManyPlatePlan,
    ManyProtocolFollow,
    ProtocolTechnicage
  },
  data(){
    return{
      selectedComponent: '',
      selectedStep: '',
      selectedCode: '',
      selectedIndex: 0
    }
  },
  props: ['stepHeaders', 'goResult'],

  computed: {
    displayButtonNext(){
      if (this.stepHeaders.length != this.selectedIndex + 1){
        return true;
      }
    }
  },

  methods: {

    previousStep(){
      if (this.selectedIndex > 0){
        let stepItemSelected = this.stepHeaders[this.selectedIndex - 1];
        this.selectedStep = stepItemSelected.name;
        this.selectedComponent = stepItemSelected.component;
        this.selectedCode = stepItemSelected.code;
        this.selectedIndex = this.selectedIndex - 1;
      }
    },

    nextStep(){
      if (this.stepHeaders.length > this.selectedIndex){
        let stepItemSelected = this.stepHeaders[this.selectedIndex + 1];
        this.selectedStep = stepItemSelected.name;
        this.selectedComponent = stepItemSelected.component;
        this.selectedCode = stepItemSelected.code;
        this.selectedIndex = this.selectedIndex + 1;
      }
    },

    goStep(index, step){
      this.selectedIndex = index;
      this.selectedStep = step.name;
      this.selectedComponent = step.component;
      this.selectedCode = step.code;
    }
  },

  mounted() {
    console.log(this.goResult);
    this.stepHeaders.find(step => {
      if (step.selected == true) {
        this.selectedStep = step.name;
        this.selectedComponent = step.component;
        this.selectedCode = step.code;
        this.selectedIndex = this.stepHeaders.findIndex(item => {
          return item.id === step.id;
        })
      }
    })
  }
}
</script>

<style scoped>

</style>
