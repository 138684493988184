<template>
  <div>
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <b>Contrôle gamme</b>
          </div>
          <div class="modal-body" >

            <div class="row text-center">
              <div class="col-md-2 d-flex align-items-center justify-content-end">
                <button class="btn btn-primary float-end" @click="indexGraph--" ref="nextButton" :disabled="disableBtnPrev">
                  Précédent
                </button>
              </div>
              <div class="col-md-8">
                <graph :code-patient="'GAMME ETALON LEUCINE'" :id-plate-plan="idPlatePlan" v-if="indexGraph == 0"></graph>
                <graph :code-patient="'GAMME LEUCINE'" :id-plate-plan="idPlatePlan" v-if="indexGraph == 1"></graph>
                <graph :code-patient="'GAMME LEUCINE DROITE'" :id-plate-plan="idPlatePlan" v-if="indexGraph == 2"></graph>
              </div>
              <div class="col-md-2 d-flex align-items-center justify-content-start">
                <button class="btn btn-primary float-start" @click="indexGraph++" ref="nextButton" :disabled="disableBtnNext">
                  Suivant
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">

              </div>
            </div>
            <div class="row">
              <div class="col-md-12">

              </div>
            </div>
          </div>
          <div class="modal-footer" >
            <button class="btn btn-secondary m-2" @click="closeModal()">
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import api from "@/services/api"
import Spinner from "@/components/Spinner.vue";
import Graph from "@/views/technicage/Tests/Graph.vue";

export default {
  name: "ModalRangeControl",
  components: {Graph, Spinner},
  props : ['idPlatePlan'],
  data() {
      return {
        showSpinner: false,
        indexGraph: 0
      }
  },

  computed:{
    ...mapGetters('technicage', ['tests_technicage']),
    imageStyle() {
      return {
        transform: `scale(${this.zoomLevel}) translate(${this.translateX}px, ${this.translateY}px)`,
        transition: this.isDragging ? 'none' : 'transform 0.3s ease',
      };
    },

    getControlBearing(){
      //Just one controlBearing by plate plan
      return this.tests_technicage[0].resultCollection.platePlan.controlBearings[0];
    },

    disableBtnPrev(){
      if (this.indexGraph == 0) return true;
      return false;
    },

    disableBtnNext(){
      if (this.indexGraph == 2) return true;
      return false;
    }
  },

  methods:{
    closeModal(){
      this.$emit('close');
    },
    getStyleCell(value){
      if (value < -10 || value > 10) return 'bg-danger';
      else return 'bg-success';
    }
  },

  mounted() {

  }

}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 75%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 0 auto;
  max-height: 800px; /* Limite la hauteur pour activer le défilement */
  overflow-y: auto;  /* Active le défilement vertical */
}


.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
