<template>
  <table class="w-100" v-if="selectedTest != null">
    <tbody>
      <tr>
        <td>
          <button class="btn btn-primary float-end" @click="changeTest(false, selectedIndexTest)"  :disabled="disableBtnPrev" ref="previousButton">Précédent</button>
        </td>
        <td style="width: 60%; ">
          <view-graph-by-test :test="selectedTest" v-if="!showSpinner && selectedTest != null" :id-plate-plan="idPlatePlan"></view-graph-by-test>
          <spinner v-else></spinner>
        </td>
        <td>
          <button class="btn btn-primary float-start" @click="changeTest(true, selectedIndexTest)" ref="nextButton">
            {{ displayNext }}
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="text-center" v-else>
    <p>Aucun test à afficher</p>
  </div>
</template>

<script>
import ViewGraphByTest from "@/views/technicage/Tests/ViewGraphByTest.vue";
import Spinner from "@/components/Spinner.vue";

export default {
  name: "ViewGraph",
  components: {Spinner, ViewGraphByTest},
  data(){
    return{
      selectedIndexTest: 0,
      showSpinner: false,
    }
  },
  props: ['tests', 'idPlatePlan'],
  computed:{
    selectedTest(){
      if (this.tests.length > 0) return this.tests[this.selectedIndexTest];
      return null;
    },
    disableBtnPrev(){
      let prevIndex = this.selectedIndexTest - 1;
      if (prevIndex < 0) return true;
      return false;
    },

    displayNext(){
      let nbTest = this.tests.length;

      let nextIndex = this.selectedIndexTest + 1;
      if (nextIndex <= nbTest - 1) return 'Suivant';
      else return 'Voir récapitulatif';
    },

  },

  methods:{

    async changeTest(next){
      this.showSpinner = true;
      //if click on 'Suivant'
      let nbTest = this.tests.length;
      let buttonId = '';
      if (next){
        buttonId = 'next';
        let nextIndex = this.selectedIndexTest + 1;
        //Next
        if (nextIndex <= nbTest - 1) this.selectedIndexTest = nextIndex;
        //See récapitulatif
        else  this.$emit('display-list');
      }

      //click on 'Précédent'
      else{
        buttonId = 'previous';
        let prevIndex  = this.selectedIndexTest - 1;
        if (prevIndex >= 0) this.selectedIndexTest = prevIndex;

      }
      await this.wait(100);
      this.showSpinner = false;
      this.scrollToButton(buttonId);
    },
    wait(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    scrollToButton(buttonId) {
      console.log(buttonId + 'Button');
      // Accéder au bouton enregistré et scroller vers lui
      const button = this.$refs[buttonId + 'Button'];
      if (button) {
        button.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
  },
}
</script>

<style scoped>

</style>
