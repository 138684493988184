<template>
  <template v-for="(platePlan, index) in edit_technicage.platePlans" :key="platePlan.ref"  >
    <div class="platePlan" v-if="selectedPlatePlan === platePlan.ref">
      <h3>Nom de la plaque: {{platePlan.ref}}</h3>
      <plate-plan :num-rows="8" :num-cols="12" :num-plate="platePlan.number" :num-plate-index="index"></plate-plan>
      <button @click="changePlate(index - 1)" class="btn btn-primary mt-2" style="margin-left: 5px" v-if="edit_technicage.platePlans.length > 1 && index !== 0">Plaque précédente</button>
      <button @click="changePlate(index + 1)" class="btn btn-primary mt-2" style="margin-left: 5px" v-if="edit_technicage.platePlans.length > 1 && (index) < edit_technicage.platePlans.length">Plaque suivante</button>
    </div>

  </template>
</template>

<script>
import {mapGetters} from "vuex";
import PlatePlan from "@/views/technicage/PlatePlan.vue";

export default {
  name: "ManyPlatePlan",
  components: {PlatePlan},
  data(){
    return {
      selectedPlatePlan: '',
    }
  },

  methods: {
    changePlate(index){
      this.selectedPlatePlan = this.edit_technicage.platePlans[index].ref;
    },
  },

  computed: {
    ...mapGetters('technicage', ['edit_technicage']),
  },

  mounted() {
    if (this.edit_technicage.platePlans.length > 0){
      this.selectedPlatePlan = this.edit_technicage.platePlans[0].ref;
    }
  }
}
</script>

<style scoped>

</style>
