import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/app.css';


// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import 'boxicons/css/boxicons.min.css';

const app = createApp(App)
app.use(router)
app.use(store)
app.mount('#app')
