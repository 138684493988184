const state = {
    platePlans: [],
};

const mutations = {
    setListPlatePlans: (state, platePlan) => {
        if (state.platePlans == null || platePlan == null){
            state.platePlans = [];
        }
        else{
            let numPlate = Object.keys(platePlan);
            if(numPlate.length > 0){
                state.platePlans[parseInt(numPlate[0])] = platePlan[parseInt(numPlate[0])];
            }
        }
    },
};

const getters = {
    platePlans: state => state.platePlans,
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
};
