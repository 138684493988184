<template>
  <div v-if="!showSpinner">
    <tabs-protocol :tabHeaders="tabsHeader" @back-result="$emit('back-result')"></tabs-protocol>
    <!-- Go résultat after upload file Agilent -->
    <!-- <button class="btn btn-success float-end" style="margin-left: 2px" @click="goToResult()">Aller au résultat</button>-->
  </div>
  <spinner v-else></spinner>

</template>

<script>
import PlatePlan from "@/views/technicage/PlatePlan.vue";
import TabsProtocol from "@/views/technicage/TabsProtocol.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import Spinner from "@/components/Spinner.vue";

export default {
  name: "ProtocolTechnicage" ,
  components: {Spinner, TabsProtocol, PlatePlan},

  data(){
    return{
      tabsHeader: [],
      showSpinner: false,
    }
  },

  computed : {
    ...mapGetters('technicage', ['edit_technicage']),
    tabsHeader(){
      let tabsHeader = [];
      console.log(this.edit_technicage.platePlans)
      this.edit_technicage.platePlans.forEach((item, index) => {
        let selected = false;
        if(index === 0) selected = true;
        tabsHeader.push({index: index, ref: item.ref, number: item.number, selected: selected});
      });
      return tabsHeader;
    }
  },

  methods: {
    ...mapMutations('technicage', ['setShowTest']),
    ...mapActions('technicage', ['fetch_tests']),
    goToResult(){
      this.showSpinner = true;
      this.fetch_tests({idTechnicage: this.edit_technicage.id}).then(() => {
        this.showSpinner = false;
      });
      this.setShowTest(true);
    }
  }
}
</script>

<style scoped>

</style>
