<template>
  <div  v-if="!showSpinner && test != null">
    <div class="row">
      <div class="col-md-12 text-center border-black" v-if="test.anomalie !== 'undefined' && test.anomalie !== null">
        <p class="text-danger"><b>TEST EN ANOMALIE</b></p>
        <p class="text-danger"><b>{{test.anomalie}}</b></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <graph :code-patient="test.code_patient" :id-plate-plan="idPlatePlan"></graph>
      </div>
    </div>
    <div class="row text-center">
      <div class="col-md-2 text-center">

      </div>
      <div class="col-md-8">
        <p><b><u>Code patient: </u></b> {{ test.code_patient }} </p>
        <p><b><u>Concentration BCAA: </u></b> {{ test.bcaa }} μmol </p>
        <p><b><u>r2: </u></b> {{ edit_technicage.r2.toFixed(3) }} </p>
      </div>
      <div class="col-md-2">

      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <button class="btn btn-success me-2" @click="validationTestTechnicage">Valider</button>
        <button class="btn btn-danger me-2" @click="manageAnomalie()">{{displayLabelAnomalie}}</button>
      </div>
    </div>
  </div>
  <div class="mt-5" v-if="test == null">
    Aucun test à afficher
  </div>
  <spinner v-if="showSpinner"></spinner>
  <modal-anomalie v-if="showModalAnomalie" @close="closeModalAnomalie" :id-test="test.id" :index-test="indexTest"></modal-anomalie>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import Graph from "@/views/technicage/Tests/Graph.vue";
import api from "@/services/api";
import Spinner from "@/components/Spinner.vue";
import ModalAnomalie from "@/views/technicage/Tests/ModalAnomalie.vue";

export default {
  name: "ViewGraphByTest",
  components: {ModalAnomalie, Spinner, Graph},
  props:['test', 'idPlatePlan'],
  data() {
    return {
      showModalAnomalie: false,
      showSpinner: false,
    }
  },
  computed:{
    ...mapGetters('technicage', ['edit_technicage', 'tests_technicage']),
    ...mapGetters('auth', ['isBiologist']),
    indexTest(){
      if (this.test != null){
        let indexTabTest = this.tests_technicage.findIndex(item => {
          return item.id === this.test.id;
        });
        return indexTabTest;
      }
    },

    displayLabelAnomalie(){
      if (this.test.anomalie) return 'Supprimer anomalie';
      else return 'Ajouter anomalie'
    }
  },

  methods: {
    ...mapMutations('technicage', ['setTest']),

    async validationTestTechnicage(){

      this.showSpinner = true;
      const dataValidation = {
        isBiologist: this.isBiologist,
      };
      this.$emit('disable-validation');
      const response = await api.patch('/tests_technicages/' + this.test.id + '/validation', dataValidation, {
        headers: {
          'Content-Type': 'application/merge-patch+json'
        }
      }).then(data => {
        console.log("La validation du test a changé");
        let indexTabTest = this.tests_technicage.findIndex(item => {
          return item.id === data.data.id;
        });
        let dataTest = {indexTab: indexTabTest, test: data.data}
        this.setTest(dataTest);
        if (this.isBiologist) this.tests_technicage[this.indexTest].status = {code: 'VALIDATE'};
        else this.tests_technicage[this.indexTest].statusTechnician = {code: 'VALIDATE'};
        this.showSpinner  = false;
      }).catch( () => {
        this.showSpinner  = false;
      });

    },

    async manageAnomalie(){
      //if test has anomalie, remove anomalie
      if (this.test.anomalie != null){
        const dataAnomalieToRemove = {
          "anomalie": null,
          "commentaireTechnicienAnomalie": null,
          "commentaireMedecinAnomalie": null,
          "status": "WIP",
          "isBiologist" : this.isBiologist
        }
        this.$emit('disable-validation');
        const response = await api.patch('/tests_technicages/' + this.test.id + '/anomalie', dataAnomalieToRemove, {
          headers: {
            'Content-Type': 'application/merge-patch+json'
          }
        }).then(data => {
          this.afterCheck(data)
        });
        console.log('Anomalie retirée avec succès');
      }
      else{
        this.showModalAnomalie = true
      }
    },

    afterCheck(data){
      this.$emit('enable-validation');
      let indexTabTest = this.tests_technicage.findIndex(item => {
        return item.id === data.data.id;
      });
      let dataTest = {indexTab: indexTabTest, test: data.data}
      this.setTest(dataTest);
    },

    closeModalAnomalie(){
      this.showModalAnomalie = false;
    }
  },

  mounted() {

  },

}
</script>

<style scoped>

</style>
