<template>
  <div>
    <div class="row">
      <div class="col-md-7" v-if="!showSpinner">
          <p>Fichier Agilent: {{getFilenameAgilent}}</p>
          <button class="btn btn-secondary me-1" @click="validationAllTestTechnicage(true)" style="margin-right: 5px;" v-if="!displayWithGraph">Tout cocher validation</button>
          <button class="btn btn-secondary me-1" @click="validationAllTestTechnicage(false)" v-if="!displayWithGraph">Tout décocher validation</button>
          <button class="btn btn-primary me-1" @click="changeView()">{{displayValidation}}</button>
      </div>
      <div class="col-md-5" v-if="!showSpinner">
        <div class="float-end text-center text-white  m-1 d-flex justify-content-center align-items-center" style="width: 30%; border-radius: 5px; height: 60px;" :class="classStyleR2">
          <span>r2<br>
            <b>{{edit_technicage.r2.toFixed(3)}}</b>
          </span>
        </div>
        <div class="float-end text-center text-white m-1 d-flex justify-content-center align-items-center"
             style=" width: 30%; border-radius: 5px; height: 60px; cursor: pointer"
             :class="classStyleControlBearing"
             @click="showControlBearing = true">
          <span>Contrôle pallier</span>
        </div>
        <div class=" float-end text-center text-white m-1 d-flex justify-content-center align-items-center"
             style=" width: 30%; border-radius: 5px; height: 60px; cursor: pointer"
             :class="classStyleR2"
             @click="showRangeControl = true">
          <span>Contrôle gamme</span>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center" v-if="!showSpinner">
      <!-- Display vue List -->
      <table class="table text-center w-100" v-if="!displayWithGraph">
        <thead>
        <tr>
          <th v-for="header in headersToDisplay" :key="header">{{ header }}</th>
        </tr>
        </thead>
        <tbody class="table-border-bottom-0" v-if="listTests != null && listTests.length > 0">
          <tr v-for="(item, index) in listTests" :key="index" :class="styleLineTest(item)">
            <!-- <td v-if="isBiologist">{{ displayStatusTechnician(item) }}</td> -->
            <td>
              <input v-if="isBiologist" type="checkbox" :disabled="item.anomalie != null" @click="validationTestTechnicage(item, false)" :checked="item.status && item.status.code === 'VALIDATE'" class="form-check-input border-1 border-black">
              <input v-else type="checkbox" :disabled="item.anomalie != null" @click="validationTestTechnicage(item, false)" :checked="item.statusTechnician && item.statusTechnician.code === 'VALIDATE'" class="form-check-input border-1 border-black">
            </td>
            <td>{{ item.code_patient }}</td>
            <td>{{ item.bcaa }}</td>
            <td>
              <input v-if="isBiologist" type="checkbox" @click="manageAnomalie(item)" :checked="item.anomalie != null" :disabled="item.status && item.status.code === 'VALIDATE'" class="form-check-input border-1 border-black">
              <input v-else type="checkbox" @click="manageAnomalie(item)" :checked="item.anomalie != null" :disabled="item.statusTechnician && item.statusTechnician.code === 'VALIDATE'" class="form-check-input border-1 border-black">
            </td>
            <td>
              <button class="btn btn-info me-2" @click="openEditAnomalie(item)" v-if="item.anomalie != null">Editer</button>
              <button class="btn btn-secondary me-2" @click="openGraph(item)">Voir graphique</button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="999" class="text-center">Aucune donnée présente</td>
          </tr>
        </tbody>
      </table>
      <view-graph :tests="listTestsToValidated" v-else @display-list="displayWithGraph = false" :id-plate-plan="idPlatePlan"></view-graph>
      <div>
        <modal-anomalie v-if="showModalAnomalie" @close="closeModalAnomalie" :id-test="idTest" :id-plate-plan="idPlatePlan"></modal-anomalie>
        <modal-graph v-if="showModalGraph" :code-patient="codePatient" @close="closeModalGraph" :id-plate-plan="idPlatePlan"></modal-graph>
        <modal-control-bearing v-if="showControlBearing" @close="closeModalControlBearing"></modal-control-bearing>
        <modal-range-control v-if="showRangeControl" @close="closeModalRangeControl" :id-plate-plan="idPlatePlan"></modal-range-control>
      </div>
    </div>
    <spinner v-else></spinner>
    <button class="btn btn-info float-end m-2" @click="backToProtocol()" v-if="!showSpinner">Retour protocole</button>
  </div>
</template>
<script>
import TableContainer from "@/components/TableContainer.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import Spinner from "@/components/Spinner.vue";
import ModalAnomalie from "@/views/technicage/Tests/ModalAnomalie.vue";
import api from "@/services/api";
import ModalGraph from "@/views/technicage/Tests/ModalGraph.vue";
import ViewGraph from "@/views/technicage/Tests/ViewGraph.vue";
import ModalControlBearing from "@/views/technicage/Tests/ModalControlBearing.vue";
import ModalRangeControl from "@/views/technicage/Tests/ModalRangeControl.vue";

export default {
  name: "TableTestTechnicage",
  components: {ModalRangeControl, ModalControlBearing, ViewGraph, ModalGraph, ModalAnomalie, Spinner, TableContainer},
  data() {
    return {
      tableHeadersBiologist: ['Validation', 'Code référence patient', 'Concentration en BCAA', 'Anomalie', 'Action'],
      tableHeadersTechnician: ['Validation', 'Code référence patient', 'Concentration en BCAA', 'Anomalie', 'Action'],
      showSpinner: false,
      showModalAnomalie: false,
      showModalGraph: false,
      idTest: null,
      codePatient: null,
      displayWithGraph: false,
      filename: null,
      showControlBearing: false,
      showRangeControl: false
    };
  },
  props: ['status', 'idPlatePlan'],
  methods: {
    ...mapActions('technicage', ['fetch', 'fetch_tests']),
    ...mapMutations('technicage', ['setTest', 'setTestsTechnicage']),

    // --  START Manage anomalie
    async manageAnomalie(test){
      //if test has anomalie, remove anomalie
      if (test.anomalie != null){
        const dataAnomalieToRemove = {
          "anomalie": null,
          "commentaireTechnicienAnomalie": null,
          "commentaireMedecinAnomalie": null,
          "status": "WIP",
          "isBiologist" : this.isBiologist
        }
        this.$emit('disable-validation');
        const response = await api.patch('/tests_technicages/' + test.id + '/anomalie', dataAnomalieToRemove, {
          headers: {
            'Content-Type': 'application/merge-patch+json'
          }
        }).then(data => {
          this.afterCheck(data)
        });
        console.log('Anomalie retirée avec succès');
      }
      else{
        this.idTest = test.id;
        this.showModalAnomalie = true
      }
    },

    openEditAnomalie(test){
      console.log(test)
      this.idTest = test.id;
      this.showModalAnomalie = true
    },

    closeModalAnomalie(){
      this.showModalAnomalie = false;
      this.idTest = null;
      this.showSpinner = true;
      this.fetch_tests({idTechnicage: this.edit_technicage.id}).then(() => {
        this.showSpinner = false;
      });
    },

    closeModalControlBearing(){
      this.showControlBearing = false;
    },

    closeModalRangeControl(){
      this.showRangeControl = false;
    },

    openGraph(test){
      this.codePatient = test.code_patient;
      this.showModalGraph = true
    },

    closeModalGraph(){
      this.showModalGraph = false;
      this.codePatient = null;
    },
    // --  END Manage anomalie


    async validationAllTestTechnicage(allCheck){
      this.showSpinner = true;
      let idTechnicage = this.edit_technicage.id;
      let statusCode = 'VALIDATE'

      //Si uncheck
      if (!allCheck) statusCode = 'TOVALIDATE';

      let dataValidation = {
        "isBiologist" : this.isBiologist,
        "status" : statusCode
      }

      const response = await api.post('/technicages/' + idTechnicage + '/validation/all/test', dataValidation, {
        headers: {
          'Content-Type': 'application/merge-patch+json'
        }
      }).then(data => {
        console.log("Tous les test ont été validé");
        this.fetch_tests({idTechnicage: this.edit_technicage.id}).then(() => {
          this.showSpinner = false;
        });
      });
    },

    async validationTestTechnicage(test){
      const dataValidation = {
        isBiologist: this.isBiologist,
      };
      this.$emit('disable-validation');
      const response = await api.patch('/tests_technicages/' + test.id + '/validation', dataValidation, {
        headers: {
          'Content-Type': 'application/merge-patch+json'
        }
      }).then(data => {
        console.log("La validation du test a changé");
        this.afterCheck(data);
      });
    },

    afterCheck(data){
      this.$emit('enable-validation');
      let indexTabTest = this.tests_technicage.findIndex(item => {
        return item.id === data.data.id;
      });
      let dataTest = {indexTab: indexTabTest, test: data.data}
      this.setTest(dataTest);
    },

    backToProtocol(){
      this.$emit('show-protocol');
    },

    styleLineTest(item){
      let validate = false;
      let anomaly = false;
      if (this.isBiologist){
        if (item.status != null && item.status != 'undefined'){
          if (item.status.code == 'VALIDATE') validate = true;
          if (item.status.code == 'ANOMALY') anomaly = true;
        }
      }
      else{
        if (item.statusTechnician != null && item.statusTechnician !== 'undefined'){
          if (item.statusTechnician.code == 'VALIDATE') validate = true;
          if (item.statusTechnician.code == 'ANOMALY') anomaly = true;
        }
      }

      if (validate) return 'text-success';
      if (anomaly) return 'text-danger';
    },

    changeView(){
      this.displayWithGraph = !this.displayWithGraph;
    }
  },

  computed: {
    ...mapGetters('technicage', ['tests_technicage', 'edit_technicage']),
    ...mapGetters('auth', ['isBiologist']),
    headersToDisplay(){
      if (this.isBiologist) return this.tableHeadersBiologist;
      else return this.tableHeadersTechnician;
    },

    listTests(){
      let tests = [];
      let _self = this;
      if(this.tests_technicage != null){
        this.tests_technicage.find(item => {
          if (item.resultCollection.platePlan.id == this.idPlatePlan){
            if (_self.filename == null) {
              _self.filename = item.resultCollection.filename;
            }
            if (this.status != null && this.isBiologist){
              if (item.test_technicage.statusTechnician != null && item.test_technicage.statusTechnician.code === this.status) tests.push(item);
            }
            else tests.push(item)
          }
        })
      }
      return tests;
    },

    listTestsToValidated(){
      let tests = [];
      if(this.tests_technicage != null){
        this.tests_technicage.find(item => {
          let addTest = false;
          if (item.resultCollection.platePlan.id == this.idPlatePlan){
            //if filename is empty
            if (this.filename !== '') this.filename = item.resultCollection.filename;
            if (this.isBiologist){
              if (item.status == null || (item.status !== 'undefined' || item.status !== null)){
                if (item.status.code === 'WIP') addTest = true;
              }
            }
            else{
              if (item.statusTechnician === 'undefined') addTest = true;
              if (item.statusTechnician == null) addTest = true;
              if (item.statusTechnician !== null && (item.statusTechnician.code === 'WIP' || item.statusTechnician.code === 'ANOMALY')) addTest = true;
            }
          }

          if (addTest) tests.push(item);
        })
      }
      return tests;
    },

    displayValidation(){
      if (this.displayWithGraph) return 'Vue liste';
      else return 'Validation graphique';
    },

    classStyleR2(){
      if (this.edit_technicage.r2 < 0.95) return 'bg-danger';
      else return 'bg-success'
    },

    classStyleControlBearing(){

      //Recuperation du premeir test qui est lié à un fichier et qui lui est lié à un plan de plaque
      //Le plan de plaque lui contient le controle pallier
      //Mauvaise manière de procéder => par la suite changer et travaillé sur le plan de plaque ou lié le test à un plan de plaque
      if (this.tests_technicage != null && this.tests_technicage.length > 0){
        let controleBearing = this.tests_technicage[0].resultCollection.platePlan.controlBearings[0];
        let lowBearing = -10;
        let highBearing = 10;
        let outBearing = false;
        if (controleBearing.std1 < lowBearing || controleBearing.std1 > highBearing) outBearing = true;
        if (controleBearing.std2 < lowBearing || controleBearing.std2 > highBearing) outBearing = true;
        if (controleBearing.std3 < lowBearing || controleBearing.std3 > highBearing) outBearing = true;
        if (controleBearing.std4 < lowBearing || controleBearing.std4 > highBearing) outBearing = true;
        if (controleBearing.std5 < lowBearing || controleBearing.std5 > highBearing) outBearing = true;
        if (controleBearing.blk < lowBearing || controleBearing.blk > highBearing) outBearing = true;

        if (outBearing) return 'bg-danger';
        return 'bg-success'
      }
      else{
        return 'bg-info';
      }

    },

    getFilenameAgilent(){

      if (this.filename !== null){
        const fullPath = this.filename;
        // Utilisation de la méthode split
        const fileName = fullPath.split('/').pop();
        return fileName;
      }
    }
  },

  created(){
    let _self = this;
    _self.showSpinner = true;
    this.fetch_tests({idTechnicage: this.edit_technicage.id}).then(() => {
      //Retrieve r2
      if (_self.edit_technicage != null && _self.tests_technicage.length > 0) _self.edit_technicage.r2 = this.tests_technicage[0].technicage.r2;
      _self.showSpinner = false;
    });
  }
}
</script>
<style scoped>

</style>
